// RssItemDetail.js
import React, { useEffect, useRef }  from "react";
import { useSelector, useDispatch } from "react-redux";
import { togglePreviewBoxClose, togglePreviewBoxOpen, fetchAndStoreData } from "../store/togglePreviewBoxAction";

const RssItemDetail = () => {
    const isPreviewBoxOpen = useSelector((state) => state.togglePreviewBox.isPreviewBoxOpen);
    const previewData = useSelector((state) => state.togglePreviewBox.previewData);
    const rssFeed = useSelector((state) => state.personalizedRssFeed);
    const tradingViewRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (rssFeed.status === 'succeeded' && rssFeed.data) {
            const results = rssFeed.data.results;
            if (results && results.length > 0) {
                const itemId = results[0];
                dispatch(fetchAndStoreData(itemId.id));
            } else if (!results && rssFeed.data.id) {
                dispatch(fetchAndStoreData(rssFeed.data.id));
            }
        }
    }, [dispatch, rssFeed]);


    useEffect(() => {
        if (previewData && previewData.market_symbole) {
          const symbol = previewData.market_symbole.symbol;
      
          if (tradingViewRef.current) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.onload = () => {
              new TradingView.widget({
                "autosize": true,
                "symbol": symbol,
                "interval": "5",
                "timezone": "Etc/UTC",
                "theme": "dark",
                "style": "1",
                "locale": "de_DE",
                "enable_publishing": false,
                "hide_top_toolbar": true,
                "hide_legend": true,
                "container_id": "tradingview_30a67"
              });
            };
            script.src = "https://s3.tradingview.com/tv.js";
            tradingViewRef.current.appendChild(script);
          }
        }
      }, [previewData]);

      function formatDate(dateString) {
        const optionsDate = { day: '2-digit', month: 'short' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/New_York' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', optionsDate); 
        const formattedTime = new Date(dateString).toLocaleTimeString('en-US', optionsTime);
        return `${formattedDate.replace(',', '')} ${formattedTime}`;
      }
  
    const handleToggle = () => {
        dispatch(togglePreviewBoxClose());
    };


    var htmlString = previewData && previewData.description ? previewData.description : '';
    var textContent = htmlString.replace(/<[^>]*>/g, '');

    return (
        <>
          {isPreviewBoxOpen && previewData &&(
    
            <div
              className={`previewbox bg-gray-800 pt-5 
              text-center fixed flex justify-center bottom-0 
              left-0 right-0 border-t-8 border-sky-500 h-70 md:h-auto ${
                isPreviewBoxOpen ? 'block' : 'hidden'
              }`}
            >
            <div className="grid grid-cols-1 md:grid-cols-6 gap-4 max-w-7xl px-5 relative">
              <button 
              type="button"
              className="closePreviewbox text-white text-xs absolute top-0 right-5 p-3 z-10 rounded-full shadow-sm hover:bg-white hover:text-gray-700 border border-white"
              onClick={handleToggle}>
              close
              </button>
    

              {isPreviewBoxOpen && previewData && previewData.market_symbole && (
                <div className="tradingview col-start-1 col-span-2 hidden md:table-cell" style={{'height':'270px'}}>
                  <div className="tradingview-widget-container" style={{'height':'100%','width':'100%'}}>
                    <div id="tradingview_30a67" style={{ height: 'calc(100% - 32px)', width: "100%" }}></div>
                    <div className="tradingview-widget-copyright">
                      <a style={{ position: 'absolute', left: '20px', top: '230px' }} href="https://de.tradingview.com/gopro/?share_your_love=exp3rts" rel="noopener nofollow" target="_blank">
                        <span className="text-sky-500">Charts by TradingView</span>
                      </a>
                    </div>
                    <script type="text/javascript" src="https://s3.tradingview.com/tv.js"></script>
                    <div id="tradingview_30a67" style={{ height: 'calc(100% - 32px)', width: '100%' }} ref={tradingViewRef}></div>
                  </div>
                </div>
                )}

                <div className={`symbol-infos relative ${previewData.market_symbole ? 'col-start-1 md:col-start-3' : 'h-44' } text-left col-span-4 text-white`}> 
                  <p className="font-bold text-xl">{previewData.market_symbole ?  previewData.market_symbole.symbol: ''}</p>

                  <p className="mb-5 w-[820px]">{formatDate(previewData.pub_date)} <span className="text-gray-300 text-xs italic">via {previewData.source}</span></p>
                  <p>{previewData.title}</p>

                  <p className=" text-xs max-h-36 overflow-hidden line-clamp-1">{textContent ? textContent: ''}</p>
                  <div>
                  <a
                    type="button"
                    href={previewData.link}
                    target="_blank"
                    className="rounded bg-sky-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 mt-4"
                    >
                    Read full article
                    </a>                    
                  </div>


                </div>
              </div>
            </div>
    
          )}
        </>
      );


  };
  
  export default RssItemDetail;