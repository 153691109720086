// websocketPrice.js
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

const initialState = {
  websocketMessages: [],
};

const websocketReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'RECEIVE_WEBSOCKET_MESSAGE':
      return {
        ...state,
        websocketMessages: [...state.websocketMessages, action.payload],
      };
    default:
      return state;
  }
};

const receiveWebSocketMessage = (message) => ({
  type: 'RECEIVE_WEBSOCKET_MESSAGE',
  payload: message,
});

const useWebSocket = () => {
    const dispatch = useDispatch();
  
    useEffect(() => {
      const websocket = new WebSocket('ws://127.0.0.1:8000/ws/price_updates/');
  
      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);
      };
  
      websocket.onclose = () => {
        console.log('WebSocket connection closed.');
      };
  
      return () => {
        websocket.close();
      };
    }, []);
  };
  
export default useWebSocket;
export { websocketReducer };
