// in Mainstreamtable.js

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectRssItem } from '../store/rssItemReducer';

import { togglePreviewBoxOpen } from "../store/togglePreviewBoxAction";
import { fetchProducts } from '../store/productActions';

import { fetchFilteredRssFeed, updateFilterParams } from '../store/filteredRssFeed';
import Pagination from './Pagination';
import WatchlistComponent from './WatchlistComponent';

import audio from "./../../assets/mixkit-correct-answer-tone-2870.wav";


import { useWebsocket } from '../store/useWebsocket';
import { fetchPersonalizedRssFeed, setCurrentPageUrl } from '../store/personalizedRssFeed';

import useWebSocketPrice from '../store/websocketPrice';

const Mainstreamtable = () => {

    const dispatch = useDispatch();
    const websocketMessages = useSelector((state) => state.websocketMessages);
    const [combinedData, setCombinedData] = useState([]);    

    useEffect(() => {
      const ws = new WebSocket(`wss://scanner.baleeno.com/ws/price_updates/`);
      console.log(ws)
      ws.onmessage = event => {
        const messages = JSON.parse(event.data);
        // console.log(messages)
        messages.forEach(message => {
          if (message.sym) {
            setCombinedData(prevData => prevData.map(item => {
              let formattedPrice = '';
              if (item.market_symbole && item.market_symbole.symbol === message.sym) {
                console.log("Updating price for:", message.sym, "New price:", message.c);
                const formattedPrice = `$ ${message.c.toFixed(2)}`;
                return {
                  ...item,
                  market_symbole: {
                    ...item.market_symbole,
                    formatted_last_price: formattedPrice
                  }
                };
              } else if (item.symbol === message.sym) {
                return {
                  ...item,
                  formatted_last_price: formattedPrice
                };
              }
              return item;
            }));
          }
        });
      };
      return () => ws.close();
    }, []);


    const searchParams = useSelector((state) => state.filteredResults);    
    const [newYorkTime, setNewYorkTime] = useState('');
    const token = sessionStorage.getItem('token');

    // Products
    const { products, loading, error } = useSelector((state) => state.productReducer);

    // Rss Feed
    const currentPageUrl = useSelector((state) => state.personalizedRssFeed.currentPageUrl);
    const rssFeedData = useSelector((state) => state.personalizedRssFeed.data);
    const rssFeedStatus = useSelector((state) => state.personalizedRssFeed.status);

    const [paginationInfo, setPaginationInfo] = useState({
      count: 0,
      next: null,
      previous: null
    });

    useEffect(() => {
      if (currentPageUrl) {
        dispatch(fetchPersonalizedRssFeed(currentPageUrl));
      }
    }, [dispatch, currentPageUrl]);



    useEffect(() => {

        // const results = rssFeedData.results;
        const updatedData = Array.isArray(rssFeedData.results) ? rssFeedData.results : [];
        
        const uniqueWebSocketMessages = websocketMessages.filter(message => 
          !updatedData.some(item => item.id === message.id)
        );
      
        const combinedData = [...uniqueWebSocketMessages, ...updatedData];
        setCombinedData(combinedData);
        setPaginationInfo({
          count: rssFeedData.count || 0,
          next: rssFeedData.next || null,
          previous: rssFeedData.previous || null
        });

    }, [rssFeedStatus, rssFeedData, websocketMessages]);


    
    const handlePageChange = (newPage) => {
      if (searchParams.filteredResults.data.count > 0) {
        const combinedParams = { ...searchParams.filteredRssFeed.filterParams, page: newPage };
        dispatch(fetchFilteredRssFeed(combinedParams));
      } else {
        dispatch(fetchPersonalizedRssFeed(`${currentPageUrl}?page=${newPage}`));
      }
    };


    const audioRef = useRef();
    useWebsocket();
    useWebsocket(audioRef);

  
    // Products
    useEffect(() => {
      dispatch(fetchProducts());
    }, [dispatch]);

    useEffect(() => {
      const fetchNewYorkTime = () => {
        const optionsTime = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true, timeZone: 'America/New_York' };
        const formattedTime = new Date().toLocaleTimeString('en-US', optionsTime);
        setNewYorkTime(formattedTime);
      };
    
      fetchNewYorkTime();
      const interval = setInterval(fetchNewYorkTime, 1000);
    
      return () => clearInterval(interval);
    }, []);


    function formatDate(dateString) {
      const optionsDate = { day: '2-digit', month: 'short' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/New_York' };
      const formattedDate = new Date(dateString).toLocaleDateString('en-US', optionsDate);
      const formattedTime = new Date(dateString).toLocaleTimeString('en-US', optionsTime);
      return `${formattedDate.replace(',', '')} ${formattedTime}`;
    }

    const [popupData, setPopupData] = useState(null);
    const previewData = useSelector((state) => state.togglePreviewBox.previewData);
    const [hiddenRows, setHiddenRows] = useState([]);
    
    useEffect(() => {
      if (previewData) {
          setPopupData(previewData.id)
      }
    }, [previewData]);


    const handleItemClick = (item) => {
      dispatch(selectRssItem(item));
      dispatch(togglePreviewBoxOpen(item.id));
    };

    const [matchCounter, setMatchCounter] = useState(0);
    useEffect(() => {
      // Überprüfe, ob eine Nachricht mit übereinstimmendem Symbol vorhanden ist
      websocketMessages.forEach(message => {
      if (message.is_symbol_match) {
      setMatchCounter(prevCount => prevCount + 1); // Erhöhe den Zähler
      }
      });
      }, [websocketMessages]);
      
      useEffect(() => {
      // Spiele den Ton ab, wenn der Zähler aktualisiert wird
      if (matchCounter > 0 && audioRef.current) {
      audioRef.current.play().catch(error => console.error("Fehler beim Abspielen des Tons:", error));
      }
      }, [matchCounter]);
      
     

      const rawHTML = '<a class="text-amber-400" href="https://baleeno.com/plans">Free sign up for live feed</a>';
      

    return (
      <div className="main-stream">

        <audio ref={audioRef} src={audio} />

        <div className="flex text-xs">
            {/* <span className="flex text-gray-300 mr-2 align-center">Stream 30 min. delayed.</span>
            <span className="relative flex h-3 w-3">           
            </span> */}
            <span className="ml-0"> {token ? (products ? 'Live Stream - D ' + products.historical_news : '') : <a className="text-amber-400" href="https://baleeno.com/plans">Stream 30 min. delayed. Free sign up for live feed!</a>}</span>
        </div>


        <table className="min-w-full divide-y divide-gray-700 mb-10">

        {/* ... Tabellenkopfdaten ... */}
        <thead>
          <tr className="text-sm font-semibold text-white">
              <th scope="col" className="py-4 text-left">EST <span id="digital-clock">{newYorkTime}</span></th>
              <th scope="col" className="py-4 text-left">Quote</th>
              <th scope="col" className="py-4 text-left">Symbol</th>
              
              <th scope="col" className="py-4 hidden md:table-cell">EI</th>
              <th scope="col" className="py-4 hidden md:table-cell">Spot</th>
              <th scope="col" className="py-4 hidden md:table-cell">Last</th>
              <th scope="col" className="py-4 hidden md:table-cell">Float</th>
              <th scope="col" className="py-4 hidden md:table-cell">Volume</th>
              <th scope="col" className="py-4 hidden md:table-cell">Watch</th>
          </tr>
        </thead>


        <tbody className="divide-y divide-gray-800 overflow-auto text-gray-300 text-sm text-left">



          {/* ... Ihre vorhandenen Tabellenzeilen ... */}
          {combinedData && combinedData.map((item, index) => (

            

            <tr key={item.id || index} className={`text-sm hover:bg-gray-800 ${item.is_highlighted ? 'bg-sky-900' : ''} ${popupData === item.id ? 'bg-sky-900' : ''} ${hiddenRows.includes(item.id) ? 'hidden' : ''}  ${item.is_in_watchlist ? 'hidden' : ''}`}  onClick={() => { handleItemClick(item); }}>

              <td className="whitespace-nowrap px-2 py-4">{formatDate(item.pub_date)}</td>
              <td className="whitespace-nowrap pl-2 py-4 text-ellipsis overflow-hidden md:max-w-sm xl:max-w-xl">{item.title} {item.is_in_watchlist}</td>
              <td className="whitespace-nowrap pl-2 py-4 font-medium">{item.market_symbole ? item.market_symbole.symbol : (item.symbol ? item.symbol : '-')}</td>
              

              <td className={`whitespace-nowrap pl-2 py-4 hidden md:table-cell ${
                  item.sentiment === 'negative' ? 'text-red-400' :
                  item.sentiment === 'positive' ? 'text-green-400' :
                  item.sentiment === 'neutral' ? 'text-gray-300 rotate-90' :  'text-gray-300'
              }`}>
                  {
                      item.sentiment === 'negative' ? (
                          // Pfeil nach oben (Grün)
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                          </svg>
                      ) : item.sentiment === 'positive' ? (
                          // Pfeil nach unten (Rot)
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                        </svg>
                      ) : item.sentiment === 'neutral' ? (
                          // Pfeil nach rechts (Grau)
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"></path>
                          </svg>
                      ) : (
                        '-'
                      )
                  }
              </td>
              <td className="whitespace-nowrap pl-2 py-4 hidden md:table-cell"> {item.formatted_spot_price ? item.formatted_spot_price : '-'}</td>

              <td className={`whitespace-nowrap pl-2 py-4 hidden md:table-cell ${
                  item.price_comparison === 'green' ? 'text-red-400' :
                  item.price_comparison === 'red' ? 'text-green-400' :
                  'text-gray-300'
              }`}>{/* ...{item.formatted_last_price ? item.formatted_last_price : '-'}... */} {item.market_symbole ? item.market_symbole.formatted_last_price : (item.formatted_last_price ? item.formatted_last_price : '-')}</td>

              <td className="whitespace-nowrap pl-2 py-4 hidden md:table-cell">{item.free_float ? item.free_float : '-'}</td>
              
              <td className="whitespace-nowrap pl-2 py-4 hidden md:table-cell">{item.volume ? item.volume : '-'}</td>
              
              <WatchlistComponent 
              item={item}
              />
          </tr>

          ))}
        </tbody>
      </table>



   <Pagination
    count={paginationInfo.count}
    next={paginationInfo.next}
    previous={paginationInfo.previous}
    maxPerPage={10}
    onPageChange={handlePageChange}
  /> 


  </div>

)};

export default Mainstreamtable;
