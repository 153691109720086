// tooglePreviewBoxreducer.js

import { SET_PREVIEW_DATA, TOGGLE_PREVIEW_BOX_START, TOGGLE_PREVIEW_BOX_OPEN, TOGGLE_PREVIEW_BOX_CLOSE } from './togglePreviewBoxAction';

const initialState = {
  isPreviewBoxOpen: true,
  previewData: null
};

const togglePreviewBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PREVIEW_BOX_START:
      return { ...state, isPreviewBoxOpen: true, previewData: action.payload.data };
    case TOGGLE_PREVIEW_BOX_OPEN:
      return { 
        ...state,
        isPreviewBoxOpen: true,
        previewData: action.payload.data,
       };
    case TOGGLE_PREVIEW_BOX_CLOSE:
      return { ...state, isPreviewBoxOpen: false, previewData: null };
    case SET_PREVIEW_DATA:
        return { ...state, previewData: action.payload.data };
    default:
      return state;
  }
};

export default togglePreviewBoxReducer;