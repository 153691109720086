// Watchlisttable.js

import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { fetchWatchlistData } from '../store/watchlistActionsTabel';
import { togglePreviewBoxOpen } from "../store/togglePreviewBoxAction";
import WatchlistComponent from './WatchlistComponent';


const Watchlisttable = () => {

    const dispatch = useDispatch(); 
    const activeItem = useSelector((state) => state.activeItem);
    const watchlistData = useSelector((state) => state.dataWatchlistTable);
    const isPreviewBoxOpen = useSelector(state => state.togglePreviewBox.isPreviewBoxOpen);
    const token = sessionStorage.getItem('token');

    useEffect(() => {
        const ws = new WebSocket('wss://scanner.baleeno.com/ws/price_updates/');
    
        ws.onmessage = event => {
          try {
            const messages = JSON.parse(event.data);
    
            messages.forEach(message => {
              const row = document.getElementById(message.sym);
              if (row) {
                const priceCell = row.querySelector('.price');
                if (priceCell) {
                  priceCell.textContent = `$${message.c.toFixed(2)}`;
                }
              }
            });
          } catch (error) {
            console.error('Error parsing WebSocket message:', error);
          }
        };
    
        ws.onerror = error => {
          console.error('WebSocket error:', error);
        };
    
        ws.onclose = () => {
          console.log('WebSocket closed');
        };
    
        return () => {
          ws.close();
        };
      }, []);


    useEffect(() => {
        if(token){
            dispatch(fetchWatchlistData());
        }
    }, [dispatch]);


    const handleItemClick = (item) => {
        dispatch(togglePreviewBoxOpen(item.id));
    };
    

    function formatDate(dateString) {
        const optionsDate = { day: '2-digit', month: 'short' };
        const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'America/New_York' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', optionsDate); 
        const formattedTime = new Date(dateString).toLocaleTimeString('en-US', optionsTime);
        return `${formattedDate.replace(',', '')} ${formattedTime}`;
    }

    if (!watchlistData || watchlistData.length === 0) {
        return null;
    }

  return (
    <>
            <div className="watchlist-wrapper">
            <span className="text-xs text-gray-300 hidden md:block">Watchlist</span>
            <table className="min-w-full divide-y divide-gray-700 mb-10 hidden md:table">
                <thead>
                    <tr className="text-sm font-semibold text-white">
                        <th scope="col" className="py-4 text-left">Time</th>
                        <th scope="col" className="py-4  hidden md:table-cell text-left">Quote</th>
                        <th scope="col" className="py-4 text-left">Symbol</th>
                        
                        <th scope="col" className="py-4 ">EI</th>
                        <th scope="col" className="py-4 ">Spot</th>
                        <th scope="col" className="py-4 ">Last</th>
                        <th scope="col" className="py-4 ">Float</th>
                    
                        <th scope="col" className="py-4 ">Volume</th>
                        
                        <th scope="col" className="py-4 ">Watch</th>
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-800 overflow-auto text-gray-300 text-sm text-left">

                    {watchlistData.map((item, index) => (
                    <tr key={`extended_${item.id}`} className={`text-sm hover:bg-gray-800 ${activeItem.activeItem === item.id ? 'bg-sky-900' : ''}`} onClick={() => { handleItemClick(item); }} >
                        <td className="whitespace-nowrap px-2 py-4">{formatDate(item.pub_date)}</td>
                        
                        <td className="whitespace-nowrap pl-2 py-4 text-ellipsis overflow-hidden md:max-w-sm xl:max-w-xl hidden md:table-cell">{item.title}</td>

                        <td className="whitespace-nowrap pl-2 py-4 font-medium">{item.market_symbole ? item.market_symbole.symbol : '-'}</td>
                        <td className={`whitespace-nowrap pl-2 py-4 hidden md:table-cell ${
                            item.sentiment === 'negative' ? 'text-red-400' :
                            item.sentiment === 'positive' ? 'text-green-400' :
                            item.sentiment === 'neutral' ? 'text-gray-300 rotate-90' :  'text-gray-300'
                            }`}>
                            {
                                item.sentiment === 'negative' ? (
                                    // Pfeil nach oben (Grün)
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 13.5L12 21m0 0l-7.5-7.5M12 21V3" />
                                    </svg>
                                ) : item.sentiment === 'positive' ? (
                                    // Pfeil nach unten (Rot)
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
                                    </svg>
                                ) : item.sentiment === 'neutral' ? (
                                    // Pfeil nach rechts (Grau)
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"></path>
                                    </svg>
                                ) : (
                                    '-'
                                )
                            }
                        </td>
                        <td className="whitespace-nowrap pl-2 py-4"> {item.formatted_spot_price ? item.formatted_spot_price : '-'}</td>

                        <td className="whitespace-nowrap pl-2 py-4 price">{item.market_symbole ? item.market_symbole.formatted_last_price : '-'}</td>
                        
                        <td className="whitespace-nowrap pl-2 py-4">{item.market_symbole ? item.market_symbole.last_free_float : '-'}</td>
                        <td className="whitespace-nowrap pl-2 py-4">{item.volume ? item.volume : '-'}</td>
      

                        
                        <WatchlistComponent 
                            item={item} 
                            />
                    </tr>
                    ))}

                </tbody>
            </table>
        </div>


  </>
    );
  };

export default Watchlisttable;
