// In einer Datei wie filteredResultsSlice.js
import { combineReducers } from 'redux';
import { createSlice } from '@reduxjs/toolkit';
import { fetchFilteredRssFeed } from './filteredRssFeed';

const filteredResultsSlice = createSlice({
  name: 'filteredResults',
  initialState: { data: [], status: 'idle', error: null },
  reducers: {
    setFilteredResults: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    clearFilteredResults: (state) => {
      state.status = 'idle';
      state.data = [];
    },
  },
});

const filteredRssFeedSlice = createSlice({
  name: 'filteredRssFeed',
  initialState: {
    filterParams: {},
    data: [],
    status: 'idle',
    error: null,
    paginatedResults: [],
  },
  reducers: {}, // Lass die Reduzierer leer, da du keine benutzerdefinierten Reduzierer benötigst
  extraReducers: (builder) => {
    builder
    .addCase(fetchFilteredRssFeed.pending, (state, action) => {
      // console.log('fetchFilteredRssFeed.pending Action:', action);
      state.status = 'loading';
    })
    .addCase(fetchFilteredRssFeed.fulfilled, (state, action) => {
      // console.log('fetchFilteredRssFeed.fulfilled Action:', action);
      state.status = 'succeeded';
      state.filterParams = action.meta.arg;
      state.data = [...action.payload.results];
    })
    .addCase(fetchFilteredRssFeed.rejected, (state, action) => {
      // console.log('fetchFilteredRssFeed.rejected Action:', action);
      state.status = 'failed';
      state.error = action.error.message;
    });
  },
});

const rootReducer = combineReducers({
  filteredResults: filteredResultsSlice.reducer,
  filteredRssFeed: filteredRssFeedSlice.reducer,
});

export const { setFilteredResults, clearFilteredResults } = filteredResultsSlice.actions;
export default rootReducer;