import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "./api";

// Thunks
export const fetchUserLogin = createAsyncThunk("userLogin/fetch", async (data) => {
  try {
    const response = await api.post("/api/external-api/", data);
    return response.data;

  } catch (error) {
    throw error;
  }
});

const initialState = {status: 'idle', data: {}, error: ""}
// Reducer
export const userLoginReducer = createSlice({
  name: "userLogin",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserLogin.pending, (state) => {
      state.status =  'loading';
    });
    builder.addCase(fetchUserLogin.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchUserLogin.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error;

    });
  },
}).reducer;
