import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";

import { fetchUserLogin } from "../store/user_login";
import baleenoLogo from "./../../assets/images/baleeno_logo_plain-2.png";

export default function Login({setStatus, shown_login}) {
  let [isOpen, setIsOpen] = useState(true)
  let [error, setError] = useState(null)
  let [form, setForm] = useState({email: '', password:''})
  let [mystate, setMystate] = useState(null)

  const dispatch = useDispatch();

  function closeModal(status='closed') {
    setIsOpen(false);
    setStatus(status);
  }

  function openModal() {
    setIsOpen(true);

  }
  const userLogin = useSelector((state) => state.userLogin);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch(fetchUserLogin(form));
    setMystate(true)
  }
  function handleChange(event) {
    setForm({...form, [event.target.name]: event.target.value})
  }

  useEffect(() => {
    if (mystate && userLogin.status === 'succeeded'){
      sessionStorage.setItem('token', userLogin.data.token);
      closeModal('succeeded');
    } else if (userLogin.status === 'failed'){
        setError(userLogin.error.message)

    } else {
      setError(null)
    }

  }, [userLogin]);


  return (
    <>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">

                  <div className="mt-2">

                    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                          className="mx-auto h-10 w-auto"
                          src={baleenoLogo}
                          alt="Your Company"
                        />
                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                          Sign in to your account
                        </h2>
                      </div>

                      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        {error &&
                        <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                           <span className="font-medium">Login failed!</span> {error}
                        </div>
                        }


                        <form onSubmit={handleSubmit} className="space-y-6" action="#" method="POST">
                          <div>
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900"> 
                              Email address
                            </label>
                            <div className="mt-2">
                              <input
                                onChange={handleChange}
                                id="email"
                                name="email"
                                type="email"
                                autoComplete="email"
                                required
                                className="block w-full rounded-md border border-gray-300 py-1.5 pl-2 text-gray-900 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div>
                            <div className="flex items-center justify-between">
                              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password
                              </label>
                              <div className="text-sm">
                                <a href="https://hub.baleeno.com/login/?action=forgot_password" className="font-semibold text-sky-600 hover:text-sky-500" target="blank">
                                  Forgot password?
                                </a>
                              </div>
                            </div>
                            <div className="mt-2">
                              <input
                                onChange={handleChange}
                                id="password"
                                name="password"
                                type="password"
                                autoComplete="current-password"
                                required
                                className="block w-full rounded-md border border-gray-300 py-1.5 pl-2 text-gray-900 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="flex w-full justify-center rounded-md px-3 py-3 rounded bg-sky-600 px-2 text-xs font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600 mt-4"
                            >
                              LogIn
                            </button>
                          </div>
                          <div className='text-center font-semibold'>
                            <a className='w-full justify-center rounded-md px-3 py-3 rounded text-sky-600 text-center hover:text-sky-500' href='https://hub.baleeno.com/'>Create Account</a>
                          </div>
                        </form>


                      </div>
                    </div>

                  </div>


                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
