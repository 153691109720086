import { Outlet, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import cookie from "cookie";

import Header from "../components/Header";
import Footer from "../components/Footer";
import RssItemDetail from "../components/RssItemDetail";
import  Login from "../pages/Login";


const Layout = () => {
  const [shown_login, setShownLogin] = useState(null);
  const [counter, setCounter] =useState(0);
  const getLoginToken = function(){
    const token = sessionStorage.getItem('token')
    return token?token:null;
  }

  const [token, setToken] = useState(getLoginToken());
  const succeed_login = function(value, e){
    if(value){
      setToken(sessionStorage.getItem('token'))
      window.location.reload();
    }
    setShownLogin(null);

  }
  const succeed_logout = function(status){
    if(status === 'succeeded'){
      setToken(null);
      setShownLogin(null);
      sessionStorage.removeItem('token');
      const dd = new Date(Date.now() - 999999999999);
      const { csrftoken } = cookie.parse(document.cookie);
      cookie.serialize("csrftoken", csrftoken + '-1x', {expires: dd, path: '/'}); 
      window.location.reload();
    }
  }

  const content = shown_login? <Login setStatus={succeed_login} shown_login={shown_login} />: <Outlet context={[token, setToken]} />

  return (
    <>
      <Header
        token={token}
        succeedLogout={succeed_logout}
        setShownLogin={setShownLogin}
      />
      {content}
      <Footer />
      <RssItemDetail />
    </>
  )
};

export default Layout;
