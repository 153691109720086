// personalizedRssFeed.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import api from "./api";

import { fetchFilteredRssFeed } from './filteredRssFeed';
import { setFilteredResults, clearFilteredResults } from './filteredResultsSlice';

// Aktion zum Aktualisieren der aktuellen Seiten-URL hinzufügen
export const updateCurrentPageUrl = createSlice({
  name: 'personalizedRssFeed',
  initialState: { data: {}, status: 'idle', error: null, currentPageUrl: '/api/personalized_rss_feed/' },
  reducers: {
    setCurrentPageUrl: (state, action) => {
      state.currentPageUrl = action.payload;
    },
  },
});

// Ihre bestehende Thunk-Aktion ändern, um die currentPageUrl zu verwenden
export const fetchPersonalizedRssFeed = createAsyncThunk(
  'personalizedRssFeed/fetch',
  async (currentPageUrl, { rejectWithValue }) => {
    try {
      const response = await api.get(currentPageUrl);
      // console.log('currentPageUrl:', currentPageUrl);
      // // console.log('response.payload:', response.payload);
      return response.data;
    } catch (error) {
      console.error('Error fetching RSS feed:', error);
      throw rejectWithValue(error.response.data);
    }
  }
);

const personalizedRssFeedSlice = createSlice({
  name: 'personalizedRssFeed',
  initialState: { data: {}, status: 'idle', error: null, currentPageUrl: '/api/personalized_rss_feed/' },
  data: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonalizedRssFeed.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPersonalizedRssFeed.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchPersonalizedRssFeed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(setFilteredResults, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(clearFilteredResults, (state) => {
        state.status = 'idle';
        state.data = [];
      })
       .addCase(fetchFilteredRssFeed.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchFilteredRssFeed.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.data = action.payload;
      })
      .addCase(fetchFilteredRssFeed.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setCurrentPageUrl } = updateCurrentPageUrl.actions;
export default personalizedRssFeedSlice.reducer;


