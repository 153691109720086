import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "./api";

// Thunks
export const fetchUserCheck = createAsyncThunk("userCheck/fetch", async () => {
  const res = await api.get("/api/rest/rest-check-login/");
  return res.data;
});

// Reducer
export const userCheckReducer = createSlice({
  name: "userCheck",
  initialState: {status: 'idle', data: {}, error: ""},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserCheck.pending, (state) => {
      state.status =  'loading';
    });
    builder.addCase(fetchUserCheck.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';


    });
    builder.addCase(fetchUserCheck.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error;

    });
  },
}).reducer;
