// in store/filteredRssFeed.js


import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from "./api";


export const fetchFilteredRssFeed = createAsyncThunk(
  'filteredRssFeed/fetch',
  async (filterParams, { rejectWithValue }) => {
    try {
      //console.log(filterParams)
      const response = await api.get('/api/personalized_rss_feed/', { params: filterParams });
      // console.log(response.data.results)

      // for (let i = 0; i < response.data.results.length; i++) {
      //   const isHighlighted = response.data.results[i].is_highlighted;
      //   console.log(`Element ${i}: is_highlighted = ${isHighlighted}`);
      // }

      return response.data;
      // return {
      //   count: response.data.count,
      //   results: response.data.results,
      //   next: response.data.next,
      //   previous: response.data.previous
      // };
    } catch (error) {
      // Handle errors here, e.g., log the error
      console.error("Error fetching filtered RSS feed:", error);
      throw rejectWithValue(error.response.data);
    }
  }
);



