// watchlistReducer.js


import { ADD_TO_WATCHLIST_SUCCESS, REMOVE_FROM_WATCHLIST_SUCCESS, SET_WATCHLIST_ITEM_LIMIT } from './watchlistActions';

const initialState = {
  items: [], 
  itemLimit: 0, 
};

const watchlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_WATCHLIST_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
    case REMOVE_FROM_WATCHLIST_SUCCESS:
      return {
        ...state,
        items: state.items.filter((itemId) => itemId !== action.payload),
      };
    case SET_WATCHLIST_ITEM_LIMIT:
      return {
        ...state,
        itemLimit: action.payload,
      };
    default:
      return state;
  }
};

export default watchlistReducer;