// rssItemReducer.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  rssItems: [],
  selectedRssItem: null,
};

const rssItemSlice = createSlice({
  name: 'rssItem',
  initialState,
  reducers: {
    setRssItems: (state, action) => {
      state.rssItems = action.payload;
    },
    selectRssItem: (state, action) => {
      state.selectedRssItem = action.payload;
    },
  },
});

export const { setRssItems, selectRssItem } = rssItemSlice.actions;
export default rssItemSlice.reducer;