import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import  Login from "../pages/Login";
import { fetchUserCheck } from "../store/user_check";

import  Advancedsearch from "../components/Advancedsearch";
import  Mainstreamtable from "../components/Mainstreamtable";
import  Watchlisttable from "../components/Watchlisttable";


const Home = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('idle')
  const userCheck = useSelector((state) => state.userCheck);

  useEffect(() => {
    if (userCheck.data?.token) {
      sessionStorage.setItem('token', userCheck.data.token);
    }
  }, [userCheck.data]);


  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      setStatus('succeeded');
    } else {
      dispatch(fetchUserCheck());
    }
  }, [dispatch]);


  useEffect(() => {
    dispatch(fetchUserCheck())
  }, [dispatch]);

  useEffect(() => {
    setStatus(userCheck.status)
  }, [userCheck]);




  return (
    <>

        <div className="content-wrapper mx-auto max-w-7xl">

        <Advancedsearch />
        <Watchlisttable />
        <Mainstreamtable />

        </div>

    </>
  );
};

export default Home;
