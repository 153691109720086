// togglePreviewBoxAction.js

export const togglePreviewBoxStart = () => ({
  type: "TOGGLE_PREVIEW_BOX_START",
});

export const togglePreviewBoxOpen = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`/api/rssitem/${id}/`);
      const data = await response.json();
      dispatch({
        type: "TOGGLE_PREVIEW_BOX_OPEN",
        payload: {
          data,
        },
      });
    } catch (error) {
      console.error("Fehler beim Abrufen der Daten:", error);
    }
  };
};

export const togglePreviewBoxClose = () => ({
  type: "TOGGLE_PREVIEW_BOX_CLOSE",
});

export const setPreviewData = (data) => ({
  type: "SET_PREVIEW_DATA",
  payload: {
    data,
  },
});

export const fetchAndStoreData = (id) => {
  return async (dispatch) => {
    try {
      const response = await fetch(`/api/rssitem/${id}/`);
      const data = await response.json();

      dispatch(setPreviewData(data));
    } catch (error) {
      console.error("Fehler beim Abrufen der Daten:", error);
    }
  };
};

export const TOGGLE_PREVIEW_BOX_START = 'TOGGLE_PREVIEW_BOX_START';
export const TOGGLE_PREVIEW_BOX_OPEN = 'TOGGLE_PREVIEW_BOX_OPEN';
export const TOGGLE_PREVIEW_BOX_CLOSE = 'TOGGLE_PREVIEW_BOX_CLOSE';
export const SET_PREVIEW_DATA = 'SET_PREVIEW_DATA';

