import { createSlice } from '@reduxjs/toolkit';

export const websocketMessagesSlice = createSlice({
  name: 'websocketMessages',
  initialState: [],
  reducers: {
    addWebsocketMessage: (state, action) => {
      // Fügt die neue Nachricht am Anfang des Arrays hinzu
      state.unshift(action.payload);
    },
  },
});

export const { addWebsocketMessage } = websocketMessagesSlice.actions;

export default websocketMessagesSlice.reducer;