import React, { useState } from 'react';

const Pagination = ({ count, maxPerPage, onPageChange }) => {
  const totalPages = Math.ceil(count / maxPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageClick = (newPage) => {
    setCurrentPage(newPage);
    onPageChange(newPage); // Stelle sicher, dass die Funktion onPageChange in der Elternkomponente definiert ist
  };

  const getVisiblePageNumbers = (currentPage, totalPages) => {
    let startPage = Math.max(currentPage - 2, 1);
    let endPage = Math.min(startPage + 4, totalPages);

    if (currentPage > totalPages - 2) {
      startPage = Math.max(totalPages - 4, 1);
      endPage = totalPages;
    }

    return Array.from({ length: (endPage - startPage) + 1 }, (_, i) => startPage + i);
  };

  const visiblePageNumbers = getVisiblePageNumbers(currentPage, totalPages);

  return (
    <nav className="flex items-center justify-center">
      <button
        onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
        className="px-4 py-2 text-gray-600"
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {visiblePageNumbers.map(number => (
        <button
          key={number}
          onClick={() => handlePageClick(number)}
          className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium ${number === currentPage ? 'text-sky-600 border-sky-500' : 'border-transparent hover:border-gray-300'}`}
        >
          {number}
        </button>
      ))}
      <button
        onClick={() => currentPage < totalPages && handlePageClick(currentPage + 1)}
        className="px-4 py-2 text-gray-600"
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </nav>
  );
};

export default Pagination;