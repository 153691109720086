
import React, { useState, useEffect } from "react";

const Footer = () => {

  return (
    <>
      <footer className="text-center text-gray-400 text-xs mt-32 mb-80">
        <div className="hidden lg:flex lg:flex-1 gap-4 lg:justify-center mb-5">
            <a href="https://baleeno.com/b2b/" className="text-sm leading-6 text-sky-500">B2B</a>
            <a href="https://baleeno.com/contact/" className="text-sm leading-6 text-sky-500">Support</a>
            <a href="https://baleeno.com/disclaimer/" className="text-sm leading-6 text-sky-500">Disclaimer</a>
            <a href="https://baleeno.com/imprint/" className="text-sm leading-6 text-sky-500">Imprint</a>
            <a href="https://baleeno.com/cookie-policy-eu/" className="text-sm leading-6 text-sky-500">Cookie Policy</a>
            <a href="https://baleeno.com/privacy-statement-eu/" className="text-sm leading-6 text-sky-500">Privacy Statement</a>
            <a href="https://baleeno.com/terms/" className="text-sm leading-6 text-sky-500">Terms and Conditions</a>
        </div>
        © Baleeno
    </footer>

    </>
  );
};

export default Footer;

