// watchlistActionsTabel.js


import axios from 'axios';

export const UPDATE_DATA_WATCHLIST_TABLE = "UPDATE_DATA_WATCHLIST_TABLE";

export const fetchWatchlistData = () => {
    return (dispatch) => {
        axios.get('/api/user-watchlist/')
            .then(response => {
                dispatch({
                    type: UPDATE_DATA_WATCHLIST_TABLE,
                    payload: response.data
                });
            })
            .catch(error => {
                // error
                console.error('Fehler beim Abrufen der Watchlist-Daten:', error);
            });
    };
};