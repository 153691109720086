// in Advancedsearch.js

import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchFilteredRssFeed } from "../store/filteredRssFeed";
import { setFilteredResults, clearFilteredResults } from '../store/filteredResultsSlice';
import { fetchPersonalizedRssFeed } from '../store/personalizedRssFeed';
import { useWebsocket } from '../store/useWebsocket';

const Advancedsearch = () => {
    const dispatch = useDispatch();
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const rssFeed = useSelector((state) => state.personalizedRssFeed);
    const currentPageUrl = useSelector((state) => state.personalizedRssFeed.currentPageUrl);
    const [resetFilterFlag, setResetFilterFlag] = useState(false);
    const userLogin = useSelector((state) => state.userLogin);
    
    const [isHighlightAction, setIsHighlightAction] = useState(false); 
    const [isHighlighted, setIsHighlighted] = useState(false);
    const [symbolInput, setSymbolInput] = useState('');

    const token = sessionStorage.getItem('token');

    const initialFilter = {
        'field_spot_price_from': '',
        'field_spot_price_to': '',
        'field_free_float_from': '',
        'field_free_float_to': '',
        'field_volume_from': '',
        'field_volume_to': '',
        'field_price_change_from': '',
        'field_price_change_to': '',
        'field_market_cap_to': '',
        'field_market_cap_from': '',
        'market_symbole': '',
        'offers': '',
        'offers_sound': '',
        'search': '',
        'af_relvolume_from': '',
        'af_relvolume_to': '',
        'stock_symbols_ai': false,
        'highlightSymbols': '',
    }
    const [filters, setFilters] = useState(initialFilter);

    const [previousResults, setPreviousResults] = useState([]);
    const { sendSettings } = useWebsocket();

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
    
        setFilters(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value,
        }));
    
        if (name === 'offers_sound') {
            // console.log(`Senden der Soundeinstellungen: SoundEnabled: ${checked}, Symbol: ${filters.market_symbole}`);
            sendSettings({
                soundEnabled: checked,
                symbol: filters.market_symbole,
                token: token 
            });
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();

        // const searchFilters = {};
        const { highlightSymbols, ...searchFilters } = filters;

        console.log(isHighlightAction)

        if (!isHighlightAction) {
            searchFilters['market_symbole'] = filters['market_symbole'];
        } else {
            searchFilters['highlightSymbols'] = filters['market_symbole'];
            searchFilters['market_symbole'] = '';
        }


        // const { offers_sound, ...searchFilters } = filters;
        dispatch(fetchFilteredRssFeed(searchFilters))
            .then(response => {
                if (response.payload) {
                    dispatch(setFilteredResults(response.payload));
                }
            });
    
    };

    // Highlight
    const handleHighlightChange = (event) => {
        const isChecked = event.target.checked;
        setIsHighlighted(isChecked);
        setIsHighlightAction(isChecked); // Setze isHighlightAction basierend auf dem Status der Checkbox

        if (isChecked) {
            setFilters(prev => ({
                ...prev,
                highlightSymbols: prev.market_symbole,
            }));
        } else {
            setFilters(prev => ({
                ...prev,
                highlightSymbols: '',
            }));
        }
    }

    const handleFilterClick = () => {
        setFilters(initialFilter);
        setIsHighlighted(false);
        if (!resetFilterFlag) {
          setResetFilterFlag(true);
          dispatch(clearFilteredResults());
          setTimeout(() => {
            dispatch(fetchPersonalizedRssFeed(currentPageUrl));
            setResetFilterFlag(false);

          }, 200);
        }
      };


      const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          handleSearch(event);
        }
      };


    const toggleFilterVisibility = () => {
        setIsFilterVisible(!isFilterVisible);
      };



    

   

  return (
    <>
    <div className="hide-filter text-xs font-medium leading-6 text-sky-500 cursor-pointer" onClick={toggleFilterVisibility}>Hide Search Settings</div>

    <form onSubmit={handleSearch} onKeyPress={handleKeyPress}>
    <div className={`filter-wrapper mb-10 ${isFilterVisible ? 'hidden' : 'block'}`}>
    <div className={`symbol-search inline-block min-w-full align-middle mb-10`}>
          <div className="grid grid-cols-2 gap-4">
              <div>
                  <div className="flex justify-between">
                      <div htmlFor="symbol" className="text-xs font-medium leading-6 text-gray-300">Symbols</div>
                  </div>
                    <div className="mt-2 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10 text-gray-300">
                            <input type="text" name="market_symbole" id="market_symbole" value={filters['market_symbole']} onChange={handleChange}
                            className="block bg-gray-900 w-full rounded-md border border-gray-700 py-1.5 pl-2 text-white placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="AAPL,AMZN,NVDA" />
                        </div>
                    </div>
              </div>
              <div>
                  <div htmlFor="symbol" className="text-xs font-medium leading-6 text-gray-300">Text Search</div>
                    <div className="mt-2 flex rounded-md shadow-sm">
                        <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <input type="text" name="search" id="search" value={filters['search']} onChange={handleChange}
                            className="block bg-gray-900 w-full rounded-md border border-gray-700 py-1.5 pl-2 text-white placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. increase" />
                        </div>
                    </div>
              </div>
              <div className="relative flex items-start">
                  <div className="flex mr-4">
                      <div className="flex h-6 items-center">
                          <input onChange={handleHighlightChange} 
                          value="hightlight" 
                          checked={isHighlighted}
                          id="offers" 
                          aria-describedby="offers-description" 
                          name="offers" 
                          type="checkbox" className="h-4 w-4 bg-gray-900 rounded border border-gray-700 text-sky-600 focus:ring-sky-600" />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                          <label htmlFor="offers" className="text-xs text-gray-200">Highlight</label>
                      </div>
                  </div>
                  <div className="flex">
                      <div className="flex h-6 items-center">

                      <input
                    type="checkbox"
                    id="offers_sound"
                    name="offers_sound"
                    className="h-4 w-4 bg-gray-900 rounded border border-gray-700 text-sky-600 focus:ring-sky-600"
                    checked={filters.offers_sound}
                    onChange={handleChange}
                />

                          {/* <input onChange={handleChange} value="sound" checked={filters['offers_sound'].includes('sound')} id="offers_sound" aria-describedby="offers-description" name="offers_sound" type="checkbox" className="h-4 w-4 bg-gray-900 rounded border border-gray-700 text-sky-600 focus:ring-sky-600" /> */}
                      </div>
                      <div className="ml-3 text-sm leading-6">
                          <label htmlFor="offers_sound" className="text-xs text-gray-200">Sound</label>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="advanced-search text-xs mb-20 hidden md:block">
        <span className="text-gray-300">Advanced Search</span>
        
            <div className="grid grid-rows-3 grid-cols-4  gap-4 content-normal">
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Price from</span>
                    <input type="text" name="field_spot_price_from" id="field_spot_price_from"  value={filters['field_spot_price_from']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 1" />
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Price to</span>
                    <input type="text" name="field_spot_price_to" id="field_spot_price_to" value={filters['field_spot_price_to']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 15" />
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Float from</span>
                    <input type="text" name="field_free_float_from" id="field_free_float_from" value={filters['field_free_float_from']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 1,000"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Float to</span>
                    <input type="text" name="field_free_float_to" id="field_free_float_to" value={filters['field_free_float_to']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 4,000,000"/>
                </div>
                {/* <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Rel. Vol. from</span>
                    <input value={filters['af_relvolume_from']} onChange={handleChange} type="text" name="af_relvolume_from" id="af_relvolume_from" className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 1"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Rel. Vol. to</span>
                    <input value={filters['af_relvolume_to']} onChange={handleChange} type="text" name="af_relvolume_to" id="af_relvolume_to" className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 1.6"/>
                </div> */}
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Volume from</span>
                    <input type="text" name="field_volume_from" id="field_volume_from" value={filters['field_volume_from']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 5M"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Volume to</span>
                    <input type="text" name="field_volume_to" id="field_volume_to" value={filters['field_volume_to']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 30M"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Change% from</span>
                    <input type="text" name="field_price_change_from" id="field_price_change_from" value={filters['field_price_change_from']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 0.3"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Change% to</span>
                    <input type="text" name="field_price_change_to" id="field_price_change_to" value={filters['field_price_change_to']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 2.1"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Market Cap from</span>
                    <input type="text" name="field_market_cap_from" id="field_market_cap_from" value={filters['field_market_cap_from']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 200M"/>
                </div>
                <div className="mt-2 flex rounded-md shadow-sm border border-r-0 border-gray-700">
                    <span className="inline-flex items-center rounded-l-md px-3 text-gray-300 sm:text-sm">Market Cap to</span>
                    <input type="text" name="field_market_cap_to" id="field_market_cap_to" value={filters['field_market_cap_to']} onChange={handleChange}
                    className="block pl-5 bg-gray-900 w-full min-w-0 flex-1 rounded-none rounded-r-md border-0 py-1.5  ring-1 ring-gray-700 placeholder:text-gray-300 focus:ring-1 focus:ring-sky-600 sm:text-sm sm:leading-6" placeholder="eg. 300B"/>
                </div>
            </div>


            <div className="advanced-search-buttons mt-5 space-x-2 flex items-baseline ">




{token?
    <button
    type="submit"

    disabled={!filters.offers_sound ? '' : 'disabled'}
    // className="rounded bg-sky-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-sky-400"
    className={`rounded bg-sky-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-sky-400 ${!filters.offers_sound ? '' : 'disabled'}`}
  >
    Search
  </button>
  : (
    <a className="leading-6 text-sky-500 cursor-pointer">Login</a>
  )
  

// : (<a onClick={openPopup} className="rounded bg-sky-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-sky-400 cursor-pointer">Login</a> )

}


  <button onClick={handleFilterClick} type="button" className="rounded bg-white/10 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-white/20">clear all</button>

  

    
<label className="relative inline-flex items-center cursor-pointer mt-2">
  <span className="mr-2 text-sm text-gray-300 sm:text-sm">Symbol</span> 
  <input
    type="checkbox"
    name="stock_symbols_ai"
    onChange={handleChange}
    checked={filters['stock_symbols_ai']}
    className="sr-only peer"
  />
  <div className="w-9 h-5 bg-sky-500 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-sky-300 dark:peer-focus:ring-sky-800 rounded-full peer dark:bg-sky-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0 after:left-[52px] after:bg-white after:border-sky-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-sky-600"></div>
</label>


                    </div> 

    </div>

    
    
            </div>
        </form>
    </>
  );
};

export default Advancedsearch;
