// watchlistActions.js
import axios from 'axios';
import api from "./api";
import { fetchWatchlistData } from './watchlistActionsTabel';

export const addToWatchlistSuccess = (itemId) => ({
  type: ADD_TO_WATCHLIST_SUCCESS,
  payload: itemId,
});

export const removeFromWatchlistSuccess = (itemId) => ({
  type: REMOVE_FROM_WATCHLIST_SUCCESS,
  payload: itemId,
});

export const setWatchlistItemLimit = (limit) => ({
  type: SET_WATCHLIST_ITEM_LIMIT,
  payload: limit,
});


// Asynchrone Aktionen
export const addToWatchlist = (itemId) => async (dispatch) => {
  try {
    const response = await api.post(`/api/watchlist/`, { rss_item_id: itemId });
    if (response.status === 200 || response.status === 201) {
      dispatch(addToWatchlistSuccess(itemId));
      dispatch(fetchWatchlistData());
    } else {
      console.error('Fehler beim Hinzufügen zur Watchlist. Statuscode:', response.status);
    }
  } catch (error) {
    console.error('Fehler beim Hinzufügen zur Watchlist:', error);
  }
};

export const removeFromWatchlist = (itemId) => async (dispatch) => {
  try {
    const response = await api.post(`/api/watchlist/`, { rss_item_id: itemId });
    if (response.status === 200 || response.status === 201) {
      dispatch(addToWatchlistSuccess(itemId));
      dispatch(fetchWatchlistData());
    } else {
      console.error('Fehler beim Hinzufügen zur Watchlist. Statuscode:', response.status);
    }
  } catch (error) {
    console.error('Fehler beim Hinzufügen zur Watchlist:', error);
  }
};

// Asynchrone Aktion zum Abrufen der Anzahl der verfügbaren Watchlist-Items
export const fetchWatchlistItemLimit = () => async (dispatch) => {
  try {
    const response = await axios.get(`/api/personalized_rss_feed/`);
    if (response.status === 200) {
      const watchlistItemsLimit = response.data.product_data.watchlist_items;
      dispatch(setWatchlistItemLimit(watchlistItemsLimit));
    } else {
      console.error('Fehler beim Abrufen der Watchlist-Item-Limit. Statuscode:', response.status);
    }
  } catch (error) {
    console.error('Fehler beim Abrufen der Watchlist-Item-Limit:', error);
  }
}

export const ADD_TO_WATCHLIST_SUCCESS = 'ADD_TO_WATCHLIST_SUCCESS';
export const REMOVE_FROM_WATCHLIST_SUCCESS = 'REMOVE_FROM_WATCHLIST_SUCCESS';
export const SET_WATCHLIST_ITEM_LIMIT = 'SET_WATCHLIST_ITEM_LIMIT';

