import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";



import { fetchUserLogout } from "../store/user_logout";


import baleenoLogo from "./../../assets/images/baleeno_logo_plain-2.png";
const Header = ({token, succeedLogout, setShownLogin}) => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState('idle')

  const userLogout = useSelector((state) => state.userLogout);
  // const token = sessionStorage.getItem('token');
  const doLogout = function(e){
    dispatch(fetchUserLogout())
  }

  useEffect(() => {
    if(userLogout.status == 'success'){
      updateState(userLogout.status);
    }
  }, [userLogout.status])

  const updateState = function(status){
    succeedLogout(status)
  }
  const doLogin = function(e){
    setShownLogin(true);
  }
  useEffect(() => {
    succeedLogout(userLogout.status)
  }, [userLogout]);
  
 if(status === 'succeeded'){
    updateState(status);
 }



 useEffect(() => {
  // Hier das Matomo-Tracking-Skript einbinden
  const script = document.createElement("script");
  script.innerHTML = `
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function() {
      var u="//mat.baleeno.com/";
      _paq.push(['setTrackerUrl', u+'matomo.php']);
      _paq.push(['setSiteId', '4']);
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
    })();
  `;
  document.head.appendChild(script);
}, []); 

  return (
    <>
        <header className="navigation z-10">
        <nav className="mx-auto flex max-w-7xl items-center justify-between py-6" aria-label="Global">
            <div className="-mt-2.5 flex lg:flex-1">
                <a href="https://baleeno.com/" className="p-1.5 flex content-center justify-center align-middle h-5">
                    <img className="w-8 h-7 mr-3" src={baleenoLogo} />
                    <span className="block font-medium justify-center text-xl text-white pl-1">Baleeno</span>
                </a>
            </div>
            <div className="flex lg:hidden">
                <button type="button" className="m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300">
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                </button>
            </div>

            <div className="sm:hidden hidden" id="mobile-menu">
                <div className="space-y-1 px-2 pb-3 pt-2 m-2.5 ">

                    <a href="/" className="block rounded-md bg-gray-900 px-3 py-2 text-base text-sky-500 font-medium">News Room</a>
                    <a href="https://baleeno.com/plans"className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Plans</a>
                    <a href="https://baleeno.com/account/" className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white">Account</a>
                </div>
            </div>

            <div className="hidden lg:flex lg:flex-1 gap-4 lg:justify-end text-md text-white font-light">
                <a href="/" className="leading-6 text-sky-500">News Room</a>
                <a href="https://baleeno.com/plans/" className="text-md leading-6">Plans</a>
                <a href="https://baleeno.com/account/" className="leading-6">Account</a>
                {token?
                  (<a onClick={doLogout} className="leading-6 text-white cursor-pointer">Logout</a>):
                  (<a onClick={doLogin} className="leading-6 text-white cursor-pointer">Login</a>)

                }
            </div>

        </nav>
    </header>

    </>
  );
};

export default Header;
