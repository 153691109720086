// productActions.js
import axios from 'axios';

const fetchProductsRequest = () => {
  return {
    type: 'FETCH_PRODUCTS_REQUEST',
  };
};

const fetchProductsSuccess = (products) => {
  return {
    type: 'FETCH_PRODUCTS_SUCCESS',
    payload: products,
  };
};

const fetchProductsFailure = (error) => {
  return {
    type: 'FETCH_PRODUCTS_FAILURE',
    error: error,
  };
};

const fetchProducts = () => {
  return (dispatch) => {
    dispatch(fetchProductsRequest());
    axios
      .get('/api/user-products/')  // Hier sollte die richtige API-URL stehen
      .then((response) => {
        const products = response.data;
        dispatch(fetchProductsSuccess(products));
      })
      .catch((error) => {
        dispatch(fetchProductsFailure(error.message));
      });
  };
};

export { fetchProductsRequest, fetchProductsSuccess, fetchProductsFailure, fetchProducts };
