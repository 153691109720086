// WebSocketSingleton.js

let socket = null;

export const getWebSocketInstance = (token) => {
  if (!socket || socket.readyState === WebSocket.CLOSED) {
    // socket = new WebSocket(`wss://scanner.baleeno.com/ws/chat/?token=${token}`);
    socket = new WebSocket(`wss://scanner.baleeno.com/ws/chat/?token=${token}`);
    console.log(socket)
  }
  return socket;
};