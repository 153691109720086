
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { addWebsocketMessage } from './websocketMessagesSlice';
import { getWebSocketInstance } from './WebSocketSingleton'; 

export const useWebsocket = () => {
  const dispatch = useDispatch();
  const audioRef = useRef(null);

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      // console.error('Kein Token vorhanden');
      return;
    }

    const socket = getWebSocketInstance(token);

    socket.onopen = () => {
      console.log("WebSocket geöffnet");
      const initialData = { token, timestamp: new Date().getTime() };
      socket.send(JSON.stringify(initialData));
    };

    socket.onmessage = (e) => {
      const data = JSON.parse(e.data);
      console.log(data)
      dispatch(addWebsocketMessage(data));
    };

    socket.onclose = () => {
      console.log("WebSocket-Verbindung geschlossen");
    };

    return () => {

    };
  }, [dispatch]);


  const sendSettings = (settings) => {
    const socket = getWebSocketInstance();
    if (socket.readyState === WebSocket.OPEN) {
      settings.timestamp = new Date().getTime();
      //console.log("Einstellungen senden:", settings);
      socket.send(JSON.stringify(settings));
    }
  };

  return { sendSettings, audioRef };
};