// WatchlistComponent.js 

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWatchlistData } from '../store/watchlistActionsTabel';
import { fetchWatchlistItemLimit, addToWatchlist, removeFromWatchlist } from '../store/watchlistActions';
import { fetchPersonalizedRssFeed } from '../store/personalizedRssFeed';

function WatchlistComponent({ item }) {
  
  const watchlistData = useSelector((state) => state.dataWatchlistTable);

  const watchlistLimit = useSelector(state => state.watchlist.itemLimit);
  const currentPageUrl = useSelector((state) => state.personalizedRssFeed.currentPageUrl); 
  const token = sessionStorage.getItem('token');

  const dispatch = useDispatch();

  useEffect(() => {
    if(token){
      dispatch(fetchWatchlistItemLimit());
      dispatch(fetchWatchlistData());
    }
  }, [dispatch]);

  const handleAddToWatchlist = () => {
    if (!item.is_in_watchlist && watchlistData.length < watchlistLimit) {
      dispatch(addToWatchlist(item.id));
      dispatch(fetchPersonalizedRssFeed(currentPageUrl));
    } else {
      // Hier können Sie eine Benachrichtigung oder Warnung anzeigen, dass das Limit erreicht wurde
      console.log('Das Limit der Watchlist wurde erreicht.');
    }

  };

  const handleRemoveFromWatchlist = () => {
    if (item.is_in_watchlist) {
      dispatch(removeFromWatchlist(item.id));
      dispatch(fetchPersonalizedRssFeed(currentPageUrl));
    }
  };

  return (
    <td key={item.id} className="whitespace-nowrap pl-2 py-4">
      {item.is_in_watchlist ? (
        <button onClick={handleRemoveFromWatchlist} className="w-6 h-6 text-yellow-500">
          {/* Entfern-Symbol */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
          </svg>
        </button>
      ) : (
        <button onClick={handleAddToWatchlist} className="w-6 h-6">
          {/* Hinzufüg-Symbol */}
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
          </svg>
        </button>
      )}
    </td>
  );
}

export default WatchlistComponent;