// reducers.js

import { combineReducers } from "@reduxjs/toolkit";

import { restCheckReducer as restCheck } from "./rest_check";
import { userCheckReducer as userCheck } from "./user_check";
import { userLoginReducer as userLogin } from "./user_login";
import personalizedRssFeedReducer from "./personalizedRssFeed";

import filteredResultsReducer from "./filteredResultsSlice";
import rssItemReducer from './rssItemReducer';
import togglePreviewBoxReducer from './tooglePreviewBoxreducer';
import activeItemReducer from './activeItemReducer';
import watchlistReducer from './watchlistReducer';
import {userLogoutReducer as userLogout} from './user_logout';
import dataWatchlistReducerTable from "./watchlistReducerTable";
import productReducer from './productReducer';
import websocketMessagesReducer from './websocketMessagesSlice';

import { websocketReducer } from './websocketPrice';


export const rootReducer = combineReducers({
  restCheck,
  userCheck,
  userLogin,
  websocket: websocketReducer,
  personalizedRssFeed: personalizedRssFeedReducer,
  filteredResults: filteredResultsReducer,
  rssItem: rssItemReducer,
  togglePreviewBox: togglePreviewBoxReducer,
  activeItem: activeItemReducer,
  watchlist: watchlistReducer,
  userLogout: userLogout,
  productReducer,
  dataWatchlistTable: dataWatchlistReducerTable,
  websocketMessages: websocketMessagesReducer,
});
