import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "./api";

// Thunks
export const fetchUserLogout = createAsyncThunk("userLogout/fetch", async (data) => {
  try {
    const response = await api.get("/api/rest/user-logout/");
    console.log('response data:', response.data);
    return response.data;

  } catch (error) {
    throw error;
  }
});

// Reducer
export const userLogoutReducer = createSlice({
  name: "userLogout",
  initialState: {status: 'idle', data: {}, error: ""},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserLogout.pending, (state) => {
      state.status =  'loading';
    });
    builder.addCase(fetchUserLogout.fulfilled, (state, action) => {
      state.data = action.payload;
      state.status = 'succeeded';
    });
    builder.addCase(fetchUserLogout.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error;

    });
  },
}).reducer;
